import React from "react"
import { Box, Text, SimpleGrid, Image } from "@chakra-ui/core"
import SectionHeader from "../SectionHeader"
import { Link } from "gatsby"
import Button from "../Button"
import DotsImage from "../../../images/dots.svg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Fade } from "react-awesome-reveal"

interface PadippugalCardProps {
  image: any
  text: string
  bgColor: string
  textColor: string
  fontSize?: string
  link: string
}

const PadippugalCard = (props: PadippugalCardProps) => {
  const { fontSize } = props

  return (
    <Box my={5} mx="auto" w="250px">
      <Box
        bg={props.bgColor}
        boxShadow="rgba(0, 0, 0, 0.08) 0px 6px 10px"
        textAlign="center"
        h="280px"
      >
        <Box pt={3}>
          <Img
            style={{ height: "180px" }}
            imgStyle={{ objectFit: "contain" }}
            fluid={props.image}
            alt="course"
          />
        </Box>
        <Text
          color={props.textColor}
          m={3}
          p={1}
          fontWeight="bold"
          fontSize={fontSize ?? "3xl"}
          borderTop="2px solid #7070704D"
        >
          {props.text}
        </Text>
      </Box>
      <Box textAlign="center">
        <Link to={props.link}>
          <Button
            p={4}
            mt={3}
            bg="#A70008"
            color="#fff"
            textAlign="center"
            borderRadius="1.8em"
            fontSize="lg"
          >
            மேலும் அறிக
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

const PadippugalSection = () => {
  const data = useStaticQuery(graphql`
    query {
      RRB: file(relativePath: { eq: "RRB.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TNPSC: file(relativePath: { eq: "TNPSC.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      TRB: file(relativePath: { eq: "TRB.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      SSC: file(relativePath: { eq: "SSC.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      UPSC: file(relativePath: { eq: "UPSC.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      IBPS: file(relativePath: { eq: "IBPS.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Box
      overflowX="hidden"
      position="relative"
      mt={[8, 12, 20]}
      mb={[10, 16, 32]}
    >
      <Image src={DotsImage} position="absolute" top={"10%"} left={"-2%"} />
      <Image
        src={DotsImage}
        position="absolute"
        top={"30%"}
        right={"1%"}
        transform="rotate(90deg)"
      />
      <SectionHeader title="படிப்புகள்" />
      <SimpleGrid
        position="relative"
        mt={3}
        mx="auto"
        columns={[1, 2, null, null, 3]}
        maxW="1100px"
      >
        <Box
          position="absolute"
          bg="#F0F0F088"
          top="15%"
          bottom="15%"
          left={"-7%"}
          right={"-7%"}
          zIndex={-1}
        />
        <Fade direction="left" triggerOnce duration={500}>
          <PadippugalCard
            text="ஐ.ஏ.ஸ்"
            image={data.UPSC.childImageSharp.fluid}
            textColor="#2E3292"
            bgColor="#E8F0FF"
            link="/upsc"
          />
        </Fade>
        <Fade triggerOnce duration={500}>
          <PadippugalCard
            text="டி.என்.பி.ஸ்.சி"
            image={data.TNPSC.childImageSharp.fluid}
            textColor="#138000"
            bgColor="#EEFFEB"
            link="/tnpsc"
          />
        </Fade>
        <Fade direction="right" triggerOnce duration={500}>
          <PadippugalCard
            text="ஸ்டாஃப் செலக்‌ஷன் கமிஷன்"
            image={data.SSC.childImageSharp.fluid}
            textColor="#596000"
            bgColor="#FFF7B7"
            fontSize="xl"
            link="/staffSelection"
          />
        </Fade>
        <Fade direction="left" triggerOnce duration={500}>
          <PadippugalCard
            text="ஆசிரியர் தகுதித் தேர்வு"
            image={data.TRB.childImageSharp.fluid}
            textColor="#2E3292"
            bgColor="#FDF5FF"
            fontSize="2xl"
            link="/teacherExam"
          />
        </Fade>
        <Fade triggerOnce duration={500}>
          <PadippugalCard
            text="வங்கித் தேர்வு"
            image={data.IBPS.childImageSharp.fluid}
            textColor="#148ADB"
            bgColor="#F7F7F7"
            link="/bankexam"
            fontSize="2xl"
          />
        </Fade>
        <Fade direction="right" triggerOnce duration={500}>
          <PadippugalCard
            text="இரயில்வே துறை"
            image={data.RRB.childImageSharp.fluid}
            textColor="#A20C03"
            bgColor="#FFEAEB"
            fontSize="2xl"
            link="/railwayExam"
          />
        </Fade>
      </SimpleGrid>
    </Box>
  )
}
export default PadippugalSection
