import React from "react"
import { Box } from "@chakra-ui/core"
import PageBanner from "../components/PageBanner"
import PadippugalSection from "../components/Padippugal/PadippugalSection"
import KelviBathilgalSection from "../components/Ias/KelviBathilgalSection"
import BestTeachingSection from "../components/Home/BestTeachingSection"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import RegisterSection from "../components/RegisterSection"

const Courses = () => {
  return (
    <Box>
      <SEO title="Courses" />
      <Layout>
        <PageBanner bannerText="ஆட்சித்தமிழுக்கு வாருங்கள் அரசு பணியாளராய் மாறுங்கள்" />
        <PadippugalSection />
        <Box py={1} bg="#F8F8F8">
          <BestTeachingSection />
        </Box>
        <KelviBathilgalSection />
        <RegisterSection />
      </Layout>
    </Box>
  )
}
export default Courses
